.header {
  width: 100%;
  height: 80px;
  margin: 0 auto;
  padding: 1.5rem;
  box-sizing: border-box;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}

.header .logoutButton {
  background: none;
  border: none;
  cursor: pointer;
  position: absolute;
  top: 1.3rem;
  right: 0.1rem;
  margin-left: 18px;
}

.header .logoutIcon {
  color: white;
  font-size: 38px;
  margin-bottom: 15px;
}

.header .flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header .logoPlaceholder {
  width: 9rem; 
}

@media (max-width: 1280px) {
  .header .logoPlaceholder {
    width: 7rem;
  }
}

@media (max-width: 640px) {
  .header .logoPlaceholder {
    width: 6rem;
  }
}
